html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.talent-card-view {
  flex: 1;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.talent-card-view h1, .talent-card-view h2, .talent-card-view h3 {
  color: #2c3e50;
  margin-top: 20px;
}

.talent-card-view h1 {
  font-size: 2.5em;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

.talent-card-view h2 {
  font-size: 1.8em;
  color: #2980b9;
}

.talent-card-view h3 {
  font-size: 1.4em;
  color: #34495e;
}

.talent-card-view p {
  margin-bottom: 10px;
}

.talent-card-view ul {
  padding-left: 20px;
  margin-bottom: 10px;
}

.talent-card-view li {
  margin-bottom: 5px;
}

.talent-card-view .back-link {
  display: inline-block;
  margin-bottom: 20px;
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

.talent-card-view .back-link:hover {
  text-decoration: underline;
}

.talent-card-view section {
  margin-bottom: 30px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.talent-card-view .experience-item,
.talent-card-view .education-item,
.talent-card-view .internship-item {
  margin-bottom: 20px;
  border-left: 3px solid #3498db;
  padding-left: 15px;
}

.talent-card-view .experience-item h4,
.talent-card-view .education-item h4,
.talent-card-view .internship-item h4 {
  margin-bottom: 5px;
  color: #2980b9;
}

.talent-card-view .duration {
  font-style: italic;
  color: #7f8c8d;
}

/* Remove column layout for strengths and values */
.talent-card-view .skills-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.talent-card-view .skills-list li,
.talent-card-view .strengths li,
.talent-card-view .values li {
  margin-bottom: 10px;
}

/* Ensure consistent styling for strengths and values */
.talent-card-view .strengths,
.talent-card-view .values {
  margin-left: 0;
  padding-left: 0;
}

.talent-card-view .strengths ul,
.talent-card-view .values ul {
  padding-left: 20px;
}

.talent-card-view .preferences-info {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  margin-bottom: 0;
}

.talent-card-view .preferences-info > div {
  flex-basis: auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin-bottom: 30px;
}

.talent-card-view .preferences-info h3 {
  margin-top: 0;
  font-size: 1.4em;
  color: #34495e;
}

.talent-card-view .financial-expectations {
  font-weight: normal;
  color: #333;
}

.talent-card-view .financial-expectations p {
  font-weight: bold;
  color: #e74c3c;
}

@supports (-webkit-touch-callout: none) {
  .talent-card-view {
    padding-bottom: 100px; /* Add extra padding for iOS to ensure content is scrollable */
  }
}

@media (max-width: 768px) {
  html, body, #root {
    height: auto;
    overflow: visible;
  }

  .talent-card-view {
    min-height: 100vh;
    padding: 20px;
  }

  .talent-card-view .skills-list {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  
  .talent-card-view .preferences-info {
    flex-direction: column;
  }
  
  .talent-card-view .preferences-info > div {
    flex-basis: auto;
    margin-bottom: 30px;
  }
}

.talent-card-view .certifications ul {
  list-style-type: none;
  padding-left: 0;
}

.talent-card-view .certifications li {
  margin-bottom: 10px;
  color: #333;
}

.talent-card-view .certifications a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
}

.talent-card-view .certifications a:hover {
  text-decoration: underline;
}

.talent-card-view .timestamps {
  margin-top: 30px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
  font-size: 0.9em;
  color: #6c757d;
  text-align: center;
}

.talent-card-view .timestamps p {
  margin: 5px 0;
}

.talent-card-view .created-at,
.talent-card-view .updated-at {
  font-style: italic;
}

.talent-card-view .loading,
.talent-card-view .error,
.talent-card-view .not-found {
  text-align: center;
  font-size: 1.5em;
  color: #7f8c8d;
  margin-top: 50px;
}

.talent-card-view .error {
  color: #e74c3c;
}