.App {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.chat-container {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.sidebar {
  margin-top: 10px;
  width: 220px;
  background-color: #F9F9F9;
  color: #0d0d0d;
  padding: 10px;
  overflow-y: auto;
  transition: transform 0.3s ease;
}

.sidebar-hidden {
  transform: translateX(-230px);
}

.sidebar-visible {
  transform: translateX(0);
}

.talent-card-button-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.new-chat-button,
.view-talent-card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #e3e3e3;
  color: #0d0d0d;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.new-chat-button:hover,
.view-talent-card-button:hover {
  background-color: #d1d1d1;
}

.view-talent-card-button {
  background-color: #4a90e2;
  color: white;
  border: none;
}

.view-talent-card-button:hover {
  background-color: #357ae8;
}

.view-talent-card-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.new-chat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  background-color: #e3e3e3;
  color: #0d0d0d;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 20px;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #0d0d0d;
  overflow-y: auto;
}

.menu-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  color: #0d0d0d;
  cursor: pointer;
  z-index: 2223;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #343541;
  border-bottom: 1px solid #565869;
}

.main-header h1 {
  margin: 0;
  font-size: 1.5em;
  color: #d1d5db;
}

.menu-button, .mute-button {
  background: none;
  border: none;
  color: #d1d5db;
  cursor: pointer;
  padding: 5px;
}

.menu-button:hover, .mute-button:hover {
  color: #7d7d7d;
}

.chat-messages {
  flex-grow: 1;
  overflow-wrap: break-word;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message-container {
  display: flex;
  margin-bottom: 15px;
}

.user-message {
  justify-content: flex-end;
}

.model-message {
  justify-content: flex-start;
}

.welcome-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5em;
  color: #0d0d0d;
}

.suggestions {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

.suggestions-subheader {
  width: 100%;
  text-align: left;
  font-size: 1.5em;
  color: #0d0d0d;
  margin-top: 20px;
  margin-bottom: 15px;
}

.suggestion-item {
  background-color: #3e3f4b;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-bottom: 15px;
}

.suggestion-item.welcome {
  background-color: #4a4b59;
  margin-bottom: 25px;
}

.suggestion-item:hover {
  background-color: #ececec;
}

.suggestion-icon {
  font-size: 24px;
  margin-right: 15px;
  min-width: 30px;
  text-align: center;
}

.suggestion-item p {
  margin: 0;
  font-size: 14px;
  color: #d1d5db;
}

.chat-input-container {
  padding: 20px;
  background-color: white;
  border-top: 1px solid #565869;
}

.chat-form {
  display: flex;
  align-items: center;
}

.prompt-area {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #565869;
  background-color: whitesmoke;
  color: #0d0d0d;
  resize: vertical;
  font-size: 16px;
}

.send-button {
  background: none;
  border: none;
  color: #749ac8;
  cursor: pointer;
  padding: 10px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.send-button:hover {
  transform: scale(1.1);
}

.send-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.retry-indicator {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  text-align: center;
}

.send-button svg {
  width: 24px;
  height: 24px;
}

.send-button:hover svg path {
  fill: #5a7b9e;
}

.send-icon, .cancel-icon {
  width: 36px;
  height: 36px;
}

.send-icon #lightning,
.send-icon #pod {
  fill: #749ac8;
}

.send-button:hover .send-icon #lightning,
.send-button:hover .cancel-icon #pod {
  fill: #5a7b9e;
}

.send-icon.cancel #lightning {
  display: none;
}

.send-icon.cancel #pod {
  display: block;
}

.disclaimer {
  text-align: center;
  font-size: 12px;
  color: #8e8ea0;
  margin-top: 10px;
}

.highlight {
  color: #5A6069;
  font-weight: bold; 
}

.message {
  position: relative;
  max-width: 70%;
  padding: 10px 30px;
  border-radius: 18px;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 20px;
}

.message-mute-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.message-mute-button:hover {
  opacity: 1;
}

.model-message .message-mute-button {
  color: #0d0d0d;
}

.user-message .message {
  background-color: #b3dbff;
  color: #0d0d0d;
  border-bottom-right-radius: 4px;
}

.model-message .message {
  background-color: white;
  color: #0d0d0d;
  border-bottom-left-radius: 4px;
}

.message-user {
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 5px;
}

.message-model {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.session-item {
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.session-item:hover {
  background-color: #ececec;
}

.session-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}

.session-options {
  position: relative;
}

.options-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #666;
  padding: 5px;
  transition: color 0.3s ease;
}

.options-toggle:hover {
  color: black;
}

.options-menu {
  position: absolute;
  right: 0;
  left: auto;  /* Ensure it doesn't stretch to the left edge */
  top: 100%;
  background-color: #f9f9f9;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1002;
  min-width: 120px;
  max-width: 200px;  /* Prevent it from being too wide on larger mobile screens */
}

.option-button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px 12px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #0d0d0d;
  font-size: 14px;
}

.option-button:hover {
  background-color: #ececec;
}

.inline-edit {
  border: none;
  background: transparent;
  width: 180px;
  font-size: inherit;
  padding: 0;
  margin: 0;
  color: #0d0d0d;
}

.suggestions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.logo-container {
  margin-bottom: 30px;
}

.logo-container svg {
  color: #d1d5db;
}

.suggestions-content {
  width: 100%;
}

.suggestions-subheader {
  width: 100%;
  text-align: left;
  font-size: 1.5em;
  color: #d1d5db;
  margin-top: 20px;
  margin-bottom: 15px;
}

.suggestion-item {
  background-color: #3e3f4b;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  margin-bottom: 15px;
}

.suggestion-item.welcome {
  background-color: #4a4b59;
  margin-bottom: 25px;
}

.suggestion-item:hover {
  background-color: #4a4b59;
}

.suggestion-icon {
  font-size: 24px;
  margin-right: 15px;
  min-width: 30px;
  text-align: center;
}

.suggestion-item p {
  margin: 0;
  font-size: 14px;
  color: #d1d5db;
}

.logo-container {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.logo-container svg {
  color: #d1d5db;
  width: 80px;
  height: 80px;
}

.audio-prompt {
  background-color: #3e3f4b;
  border: 1px solid #565869;
  border-radius: 8px;
  padding: 15px;
  margin: 20px auto;
  max-width: 80%;
  text-align: center;
  font-size: 16px;
  color: #d1d5db;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-prompt::before {
  content: '🎧';
  margin-right: 10px;
  font-size: 20px;
}

.audio-message {
  margin-bottom: 15px;
}

/* New styles for authentication */

.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.logout-button {
  background-color: #b4b4b4;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logout-button:hover {
  background-color: #ef4444;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ef9a9a;
  font-size: 14px;
  text-align: center;
}

/* Styles for Auth component */

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.auth-form {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.auth-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.auth-form button {
  width: 100%;
  padding: 10px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-form button:hover {
  background-color: #357ae8;
}

.auth-toggle {
  margin-top: 15px;
  background: none;
  border: none;
  color: #4a90e2;
  cursor: pointer;
  font-size: 14px;
}

@media (max-width: 768px) {
  .user-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .logout-button {
    margin-top: 10px;
  }

  .auth-form {
    width: 90%;
    max-width: 300px;
  }
  
  .chat-container {
    flex-direction: column;
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  .audio-prompt {
    max-width: 90%;
    font-size: 14px;
    padding: 12px;
  }

  .sidebar {
    margin-top: 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 9px;
    width: 60%;
    z-index: 1001;
    transform: translateX(-100%);
  }

  .sidebar-visible {
    transform: translateX(0);
  }

  .options-toggle {
    padding: 8px;  /* Increase touch target size for mobile */
    font-size: 20px;  /* Slightly larger font size for better visibility */
    color: #444;  /* Darker color for better contrast on mobile */
  }

  .options-toggle:active {
    background-color: rgba(0, 0, 0, 0.05);  /* Slight background change when tapped */
    border-radius: 50%;  /* Circular background on tap */
  }

  /* Ensure the options menu doesn't go off-screen on mobile */
  .session-options {
    position: relative;
  }

  .options-menu {
    right: 0;
    top: 100%;
    left: auto;  /* Ensure it doesn't stretch to the left edge */
    min-width: 120px;
    max-width: 200px;  /* Prevent it from being too wide on larger mobile screens */
  }

  .main-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
    padding-top: 50px; /* Make room for the menu button */
    padding-bottom: 60px;
  }

  .menu-button {
    display: block;
    z-index: 2223;
  }

  .main-header {
    padding: 10px;
  }

  .main-header h1 {
    font-size: 1.2em;
  }

  .suggestions {
    padding: 0 20px;
  }

  .welcome-title {
    font-size: 2em;
  }

  .suggestion-item {
    padding: 15px;
  }

  .talent-card-button-container {
    margin: 15px 0;
  }

  .new-chat-button,
  .view-talent-card-button {
    font-size: 12px;
    padding: 8px;
  }

  .chat-input-container {
    padding: 10px;
    height: 100px;
    background-color: white;
    border-top: 1px solid #565869;
    z-index: 1000;
  }

  .prompt-area {
    flex-grow: 1;
    max-height: 100px; /* Limit the height of the textarea */
    overflow-y: auto;
    padding: 8px;
    min-height: 60px;
  }

  .send-button {
    padding: 8px;
    margin-left: 5px;
  }

  .disclaimer {
    font-size: 10px;
  }

  .suggestions-container {
    padding: 10px;
  }

  .suggestion-item {
    padding: 12px;
  }

  .suggestion-icon {
    font-size: 20px;
  }

  .suggestion-item p {
    font-size: 12px;
  }

  .suggestions-subheader {
    font-size: 1.2em;
  }

  .message {
    max-width: 80%;
  }

  @supports (-webkit-touch-callout: none) {
    .chat-container {
      height: -webkit-fill-available;
    }

    .main-content {
      height: -webkit-fill-available;
    }
  }

}

/* New styles for landscape mode */
@media (max-width: 932px) and (orientation: landscape) {
  .chat-container {
    flex-direction: row;
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0;
  }

  .chat-messages {
    flex: 1;
    overflow-y: auto;
  }

  .chat-input-container {
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 20px;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
  }

  .main-header {
    top: 0;
    left: 30%;
    right: 0;
    z-index: 10;
  }

  .main-header h1 {
    font-size: 1.2em;
    text-align: center;
    padding-inline: 10%;
  }

  .sidebar {
    width: 25%;
    max-width: 200px;
  }

  .sidebar-visible {
    transform: translateX(0);
  }

  .sidebar-hidden {
    transform: translateX(-100%);
  }

  .message-container {
    margin-right: 2%;
  }
  
  .prompt-area {
    flex-grow: 1;
    margin-top: 5px;
    overflow-y: auto;
    padding: 1px;
  }
  
  .disclaimer {
    text-align: center;
    font-size: 10px;
    color: #8e8ea0;
    margin-top: 2px;
    margin-bottom: 0;
  }
  
  .menu-button {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 2222;
  }
}